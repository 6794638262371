<template>
  <div class="wrapper">
    <i v-if="icon" :class="iconClass" @click="iClick"></i>
    <input
      :type="inputType"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :disabled="disabled"
      :class="inputClass"
    >
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {},
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    disabled: {
      type: Boolean
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputText: '',
      inputType: this.type
    }
  },
  computed: {
    iconClass() {
      if (this.icon === 'login') return 'bi bi-person-fill i-login'
      if (this.icon === 'password' && this.inputType === 'password') return 'bi bi-eye-slash-fill i-password'
      if (this.icon === 'password' && this.inputType === 'text') return 'bi bi-eye-fill i-password'
    },
    inputClass() {
      if (this.icon) return 'icon'
      else return ''
    }
  },
  methods: {
    iClick() {
      if (this.icon === 'password') {
        if(this.inputType === 'password') {
          this.inputType = 'text'
        } else {
          this.inputType = 'password'
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .wrapper
    position: relative
    i
      position: absolute
      top: 50%
      left: 10px
      transform: translateY(-50%)
      margin-top: 1px
      &.i-password
        cursor: pointer
    input
      width: 100%
      height: 34px
      padding: 0 10px
      font-size: 14px
      font-family: sans-serif
      color: $grey
      border: 1px solid $grey
      border-radius: 3px
      background: rgba($mainLight, .05)
      &.icon
        padding: 0 10px 0 36px
      &:hover, &:focus
        border: 1px solid $mainHover
      &:disabled
        border-color: rgba($grey, 1)
      &::placeholder
        color: $grey
</style>