<template>
  <div class="login">

    <div class="form">

      <v-glass />

      <v-logo class="title" />

      <v-input
        type="text"
        v-model="loginData"
        placeholder="Логин"
        @keyup.native.enter="login"
        icon="login"
      />
      <v-input
        type="password"
        v-model="passwordData"
        placeholder="Пароль"
        @keyup.native.enter="login"
        icon="password"
      />
      <v-button
        @click="login"
        title="Войти"
        icon="bi bi-box-arrow-in-right"
        :shadow="false"
      />
    </div>

  </div>
</template>

<script>
import router from "@/router";
import button from "@/components/common/button";
import input from "@/components/common/input";
import axios from "axios";
import consts from '../consts/index';
import logo from "../components/common/logo";
import glassOfMojito from "../components/common/glassOfMojito";
import Button
  from "../components/common/button";

export default {
  name: 'Login',
  layout: 'login',
  components: {
    Button,
    'v-button': button,
    'v-input': input,
    'v-logo': logo,
    'v-glass': glassOfMojito
  },
  data: () => ({
    loginData: '',
    passwordData: ''
  }),
  methods: {
    async login() {
      if(this.loginData.trim() && this.passwordData.trim()) {
        const load = {
          login: this.loginData,
          password: this.passwordData
        }
        let res = await axios.post(`${consts.baseURL}/api/users`, load)

        if(res.data.auth) {
          localStorage.setItem('access', 'true')
          localStorage.setItem('user', JSON.stringify(res.data.user))
          router.push({name: 'Home'})
        } else return false

      }
    }
  }
}
</script>

<style lang="sass">
  .login
    display: grid
    min-height: 100vh
    .form
      margin: auto
      width: 200px
      display: grid
      grid-template-columns: 1fr
      grid-template-rows: repeat(3, min-content)
      grid-gap: 10px
      position: relative
      .mojito
        position: absolute
        width: 50px
        height: auto
        top: -46px
        right: -8px
      .title
        text-align: center
        color: $main
        font-size: 25px
        width: 100px
        height: auto
        margin: 0 auto
</style>
